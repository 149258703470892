/**
* Generated automatically at built-time (2024-11-11T12:32:10.472Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "lofra-varumarke",templateKey: "sites/96-029a4437-8d74-476f-86da-d2a87e8b9650"};